
import Vue from "vue";

import http from "@/http";

import { Breadcrumb, SMS } from "@/interfaces";
import dayjs from "dayjs";

export interface DataType {
  id: string;
  message?: SMS.Message;
}

export default Vue.extend({
  data(): DataType {
    return {
      id: "",
      message: undefined
    };
  },
  computed: {
    breadcrumbs(): Breadcrumb[] {
      return [
        {
          text: "ホーム",
          disabled: false,
          to: "/"
        },
        {
          text: "SMS",
          disabled: false,
          to: "/messages"
        },
        {
          text: `${this.id}`,
          disabled: false
        }
      ];
    },
    to(): string {
      if (this.message == null) {
        return "";
      }
      return this.message.to.replace(/\+81/, "0");
    },
    status(): string {
      if (this.message == null) {
        return "";
      }
      return this.message.status === "delivered" ? "送信" : "未送信";
    },
    sent(): string {
      if (this.message == null) {
        return "";
      }
      return dayjs(this.message.dateSent.date)
        .add(9, "h")
        .format("YYYY-MM-DD HH:mm");
    }
  },
  async mounted() {
    await this.fetchData();
  },

  methods: {
    async fetchData() {
      this.id = this.$route.params.id;

      const url = `messages/${this.id}`;

      const { data } = await http.get<SMS.Message>(url, {});

      this.message = data;
    }
  }
});
